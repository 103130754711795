import { djangoClient } from 'api';


const LOGIN_CHECK = "LOGIN_CHECK"　//ログイン待ち(なくてもいい)
const LOGIN_SUCCESS = "LOGIN_SUCCESS"　//ログインに成功
const LOGIN_FAILURE = "LOGIN_FAILURE"  //ログインに失敗  
const AUTH_CHECK = "AUTH_CHECK"　//認証を確認(なくてもいい)
const AUTH_SUCCESS = "AUTH_SUCCESS"　//認証成功時にユーザ情報をブラウザに取得
const AUTH_FAILURE = "AUTH_STATUS"   //認証失敗時ユーザ情報をブラウザからクリア   
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS" //ログアウトに成功
const LOGOUT_FAILURE = "LOGOUT_FAILURE"  //ログアウトに失敗
const RESET_DATA = "RESET_DATA"; //ブラウザの全データをリセット

//ログインアクション
const login = (params) => {
    return async (dispatch) => {
        dispatch({
            type: LOGIN_CHECK
        });
        try {
            const res = await djangoClient.login(params);
            sessionStorage.setItem('token', res.data.csrfToken); //sessionの間トークンを保持
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            }) 
            dispatch(checkAuth())　//ユーザ情報を取得
        } catch {
            dispatch({
                type: LOGIN_FAILURE
            });
        }
    }
};

// ログアウトアクション
const logout = () => {
    return async (dispatch) => {
        try {
            await djangoClient.logout();
            sessionStorage.removeItem('token'); // トークンを削除
            dispatch({
                type: LOGOUT_SUCCESS
            });
        } catch (error) {
            console.error('Failed to logout:', error);
            dispatch({
                type: LOGOUT_FAILURE,        
            });
        }
    }
};

// トークンを使ってユーザー情報を取得するアクション
const checkAuth = () => {
    return async (dispatch) => {
        dispatch({
            type: AUTH_CHECK
        });
        try {
            const res = await djangoClient.getUserInfo();
            dispatch({
                type: AUTH_SUCCESS,
                payload: res.data
            });
        } catch (error) {
            console.error('Failed to check auth status:', error);
            dispatch({
                type: AUTH_FAILURE,
            });
        }
    }
};
//reduxの状態をリセットするアクション
const reset = () =>{
    return async (dispatch) => {
        dispatch({
            type: RESET_DATA
        });
    }
}

const initialState = {
    pending: false,  //ロード状態管理(なくてもいい)
    username: null,　//ユーザ名
    message:null,　//ログインメッセージ
    isAuthenticated: false　//ログイン状態管理
};



const auth = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN_CHECK":
            return {
                ...state,
                pending: true, 
            };

        case "LOGIN_SUCCESS":
            return {
                ...state,
                pending: false,
                isAuthenticated: true, 
                message: 'ログインに成功しました'
            };

        case "LOGIN_FAILURE":
            return {
                ...state,
                pending: false,
                isAuthenticated: false,
                message: 'ログインに失敗しました'
            };

        case "AUTH_CHECK":
            return {
                ...state,
                pending: true, 
            };
        
        case "AUTH_SUCCESS":
            return {
                ...state,
                pending: true,
                username: action.payload.username,
                isAuthenticated: action.payload.isAuthenticated, //login状態を維持する
                message: '認証に成功しました'
            };

        case "AUTH_FAILURE":
            return {
                ...state,
                pending: false,
                isAuthenticated: null,
                username:  null,
                message: '認証に失敗しました'
            };
    
        case "LOGOUT_SUCCESS":
            return {
                ...state,
                pending: false,
                isAuthenticated: false,
                username: null,
                message: null
            };

        case "LOGOUT_FAILURE":
            return {
                ...state,
                pending: false,
            };
 
        case "RESET_DATA":
            return {
                ...state,
                pending: false,
                isAuthenticated: false,
                username: null,
                message: null
            };

        default:
            return state;
    }
}


export default auth;

export {
    login,
    checkAuth,
    logout,
    reset
}
