// ワークスペースReference セット
// ワークスペースの初期化時のみ
const SET_WORK_SPACE = "SET_WORK_SPACE";
const setWorkSpace = workSpace => {
    return {
        type: SET_WORK_SPACE,
        payload: workSpace
    }
};

const initialState = {
    workSpace: null,
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_WORK_SPACE:
            return {
                workSpace: payload
            };
        default:
            return state;
    };
};

export default reducer;
export {
    setWorkSpace,
};