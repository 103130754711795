import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import CloudUploadIcon from '@mui/icons-material//CloudUpload';
import { ListItem, ListItemIcon, ListItemText, CircularProgress } from '@mui/material';
import { DropzoneDialog } from 'mui-file-dropzone';
import { convert } from 'modules/copas/store';

const TextUploadButton = () => {
    const [open, setOpen] = useState(false);
    const { pending } = useSelector(state => state.copas);
    const dispatch = useDispatch()

    const postFile = async (files) => {
        setOpen(false);
        let formdata = new FormData();
        formdata.append('file', files[0]);
        dispatch(convert(formdata)); //djangoから受け取る
    }



    return (
        <>
            <ListItem button onClick={() => setOpen(true)}>
                <ListItemIcon>
                    <CloudUploadIcon />
                </ListItemIcon>
                <ListItemText primary="アップロード" />
            </ListItem>
            <DropzoneDialog
                filesLimit={1}
                acceptedFiles={['.txt']}
                cancelButtonText={"キャンセル"}
                submitButtonText={"アップロード"}
                maxFileSize={5000000}
                open={open}
                onClose={() => setOpen(false)}
                onSave={(files) => postFile(files)}
                showPreviews={true}
                showFileNamesInPreview={true}
                dialogTitle="アップロードするファイルを選択"
            />
            <div className="loading" style={{ display: pending ? 'block' : 'none' }}>
                <div className="loadingInner">
                    <p className="loadgingText">{"コンバート中..."}</p>
                    <CircularProgress style={{ visibility: pending ? 'visible' : 'hidden' }} />
                </div>
            </div>

        </>
    );
};

export default TextUploadButton;