import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListItem, ListItemIcon, ListItemText, CircularProgress } from "@mui/material";
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';
import Blockly from "node-blockly/browser";
import { solve } from 'modules/search/store';

const RunButton = () => {
    const { file_id } = useSelector(state => state.copas);
    const { workSpace } = useSelector(state => state.workSpaceState);
    const { pending } = useSelector(state => state.search);
    
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const search = () => {
        let code = Blockly.JavaScript.workspaceToCode(workSpace)
        if (error_pattern_check(code) === "ERROR") {
            console.error("syntax error")
        }
        else{
            setErrorMessage('');
            dispatch(solve({
                //predicates: results.map(r => r['predicates']),
                defined_query: code,
                file_id: file_id
            }));
        }

    }
    /*エラーチェック*/
    const error_pattern_check = (code) => {
        if (!code) {
            alert("構文を作成してください");
            return "ERROR"
        }
        else if (code.trim().startsWith("(")) {
            alert("述語名がありません");
            return "ERROR"
        }
    }
    // エラーメッセージが更新されたときにモーダルを表示
    useEffect(() => {
        if (errorMessage) {
            setIsModalOpen(true);
        }
    }, [errorMessage]);


    return (
        <div>
            <ListItem button onClick={() => search()}>
                <ListItemIcon>
                    <PlayCircleFilled />
                </ListItemIcon>
                <ListItemText primary="検索" />
            </ListItem>



            <div className="loading" style={{ display: pending ? 'block' : 'none' }}>
                <div className="loadingInner">
                    <p className="loadgingText">{"検索中..."}</p>
                    <CircularProgress style={{ visibility: pending ? 'visible' : 'hidden' }} />
                </div>
            </div>
        </div>
    )
}
export default RunButton;
