import React, { useEffect } from "react";
import "../css/Header.css";
import LoginButton from "../modules/auth/components/LoginButton"
import LoginUserData from "../modules/auth/components/LoginUserData"
import { useSelector, useDispatch } from 'react-redux';
import { checkAuth } from 'modules/auth/store';

const Header = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(checkAuth());
    }, [dispatch]);

    return (
      <header className="header">
        <div className="header_location">
          {/*左端は空白 */}
          <p></p> 
          {/*中央はタイトル */}
          <div className="title">
            <div>
              <h1>ASAGAO</h1>
              <p className="text-xs">日本語意味役割検索システム</p>
            </div>
            <img src={"/asagao.png"} className="asagao_size" alt={"日本語意味役割検索システム"} />
          </div>
           {/*右端はログインボタンorユーザアイコン */}
           {isAuthenticated ? 
           <LoginUserData /> : <LoginButton />
           }

        </div>
      </header>
    );
  }

export default Header;
