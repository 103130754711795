import React, { useState } from 'react';
import {
    Modal,
    Backdrop,
    Fade,
    Grid
} from "@mui/material";
import ResultDetail from 'modules/copas/components/ResultDetail';

const PreviewModal = ({ index, setIndex }) => {
    const [tab, setTab] = useState(0);
    
    if (index === null) return null;

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={index !== null}
            className="modal"
            onClose={() => setIndex(null)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={index !== null}>
                <div className="modal-box">
                    <Grid container className="plfiles-index">
                        <Grid item xs={12}>
                            <ResultDetail
                                tab={tab}
                                index={index}
                                onTabChange={setTab}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    );
};

export default PreviewModal;