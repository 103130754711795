const queryBlocks = [
    {
        blockName: 'query_define_2',
        message: '%1(SENTENCE_ID,%2,%3):-',
        noBlockNameInMessage: true, // ブロック上表示文字列にプロック名を含めない場合
        args: [
            {
                type: "field_input",
                name: "PATTERN",
                text: "PATTERN",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION1",
                text: "EXTRACTION1",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION2",
                text: "EXTRACTION2",
                check: "String"
            },
        ],
        extraMessage: '%1.\n',
        extraArgs: [
            {
                type: "input_statement",
                name: "INNER_BLOCKS"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 300,
        exceptionMessage: "%1(SENTENCE_ID,%2,%3):-"
    },
    {
        blockName: 'query_define_3',
        message: '%1(SENTENCE_ID,%2,%3,%4):-',
        noBlockNameInMessage: true, // ブロック上表示文字列にプロック名を含めない場合
        args: [
            {
                type: "field_input",
                name: "PATTERN",
                text: "PATTERN",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION1",
                text: "EXTRACTION1",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION2",
                text: "EXTRACTION2",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION3",
                text: "EXTRACTION3",
                check: "String"
            }
        ],
        extraMessage: '%1.\n',
        extraArgs: [
            {
                type: "input_statement",
                name: "INNER_BLOCKS"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 300,
        exceptionMessage: "%1(SENTENCE_ID,%2,%3,%4):-"
    },
    {
        blockName: 'query_define_4',
        message: '%1(SENTENCE_ID,%2,%3,%4,%5):-',
        noBlockNameInMessage: true, // ブロック上表示文字列にプロック名を含めない場合
        args: [
            {
                type: "field_input",
                name: "PATTERN",
                text: "PATTERN",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION1",
                text: "EXTRACTION1",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION2",
                text: "EXTRACTION2",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION3",
                text: "EXTRACTION3",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION4",
                text: "EXTRACTION4",
                check: "String"
            }
        ],
        extraMessage: '%1.\n',
        extraArgs: [
            {
                type: "input_statement",
                name: "INNER_BLOCKS"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 300,
        exceptionMessage: "%1(SENTENCE_ID,%2,%3,%4,%5):-"
    },
    {
        blockName: 'query_define_5',
        message: '%1(SENTENCE_ID,%2,%3,%4,%5,%6):-',
        noBlockNameInMessage: true, // ブロック上表示文字列にプロック名を含めない場合
        args: [
            {
                type: "field_input",
                name: "PATTERN",
                text: "PATTERN",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION1",
                text: "EXTRACTION1",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION2",
                text: "EXTRACTION2",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION3",
                text: "EXTRACTION3",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION4",
                text: "EXTRACTION4",
                check: "String"
            },
            {
                type: "field_input",
                name: "EXTRACTION5",
                text: "EXTRACTION5",
                check: "String"
            }
        ],
        extraMessage: '%1.\n',
        extraArgs: [
            {
                type: "input_statement",
                name: "INNER_BLOCKS"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 300,
        exceptionMessage: "%1(SENTENCE_ID,%2,%3,%4,%5,%6):-"
    }
];

const logicalBlocks = [
    {
        blockName: 'not',
        message: '(%1)',
        args: [
            {
                type: "input_statement",
                name: "INNER_BLOCKS"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 150,
        exceptionMessage: "\\+" // swipl対応版
    },
    {
        blockName: 'and',
        message: '',
        args: [],
        previousStatement: null,
        nextStatement: null,
        colour: 150,
        exceptionMessage: ",", // swipl対応版
    },
    {
        blockName: 'or',
        message: '',
        args: [],
        previousStatement: null,
        nextStatement: null,
        colour: 150,
        exceptionMessage: ";", // massageと実際の出力が異なる場合

    },
    {
        blockName: 'bracket',
        message: '(%1)',
        noBlockNameInMessage: true,
        args: [
            {
                type: "input_statement",
                name: "INNER_BLOCKS_LEFT",
            },
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 150,
        exceptionMessage: "(%1)"
    },
    {
        blockName: 'neq',
        message: '変数%1が%2と不一致',
        noBlockNameInMessage: true,
        args: [
            {
                type: "field_input",
                name: "VARIABLE1",
                text: "変数１",
                check: "String"
            },
            {
                type: "field_input",
                name: "VARIABLE２",
                text: "変数２",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 150,
        exceptionMessage: "%2(Rbnv_%datetime),neq(%1,Rbnv_%datetime)"
    },
    {
        blockName: '=',
        message: '%1=%2',
        noBlockNameInMessage: true,
        args: [
            {
                type: "field_input",
                name: "VARIABLE1",
                text: "変数1",
                check: "String"
            },
            {
                type: "field_input",
                name: "VARIABLE2",
                text: "変数2",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 150,
        exceptionMessage: "%1=%2"
    },
];

const prologBlocks = [
    {
        blockName: '定数定義(仮):',
        message: '%1',
        args: [
            {
                type: "field_input",
                name: "CONSTANTS",
                text: "定数",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 150,
        exceptionMessage: "%1(%1)\n", // massageと実際の出力が異なる場合
    },
    {
        blockName: 're_match',
        message: '(%1,%2)', // ブロック上表示文字列
        args: [
            {
                type: "field_input", // 引数指定形式
                name: "REGEX", // フィールド名
                text: "REGEX", // フィールド初期値
                check: "String" // 型バリデーション
            } ,// messageの%1に対応するフィールド
            {
                type: "field_input", // 引数指定形式
                name: "REGEX_TARGET", // フィールド名
                text: "REGEX_TARGET", // フィールド初期値
                check: "String" // 型バリデーション
            } // messageの%1に対応するフィールド
        ],
        previousStatement: null,
        nextStatement: null,
        colour:350
    },

    {
        blockName: 'chunk', // 述語名
        message: '(SENTENCE_ID,0,%1)', // ブロック上表示文字列
        args: [
            {
                type: "field_input", // 引数指定形式
                name: "CHUNK_NODE_ID", // フィールド名
                text: "CHUNK_NODE_ID", // フィールド初期値
                check: "String" // 型バリデーション
            } // messageの%1に対応するフィールド
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
    {
        blockName: 'morph',
        message: '(SENTENCE_ID,%1,%2)',
        args: [
            {
                type: "field_input",
                name: "CHUNK_NODE_ID",
                text: "CHUNK_NODE_ID",
                check: "String"
            },
            {
                type: "field_input",
                name: "MORPH_NODE_ID",
                text: "MORPH_NODE_ID",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
    {
        blockName: 'main',
        message: '(SENTENCE_ID,%1,%2)',
        args: [
            {
                type: "field_input",
                name: "CHUNK_NODE_ID",
                text: "CHUNK_NODE_ID",
                check: "String"
            },
            {
                type: "field_input",
                name: "MAIN_SURFACE",
                text: "MAIN_SURFACE",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
    {
        blockName: 'part',
        message: '(SENTENCE_ID,%1,%2)',
        args: [
            {
                type: "field_input",
                name: "CHUNK_NODE_ID",
                text: "CHUNK_NODE_ID",
                check: "String"
            },
            {
                type: "field_input",
                name: "PART_SURFACE",
                text: "PART_SURFACE",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
    {
        blockName: 'role',
        message: '(SENTENCE_ID,%1,%2)',
        args: [
            {
                type: "field_input",
                name: "CHUNK_NODE_ID",
                text: "CHUNK_NODE_ID",
                check: "String"
            },
            {
                type: "field_input",
                name: "ROLE",
                text: "ROLE",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
    {
        blockName: 'semantic',
        message: '(SENTENCE_ID,%1,%2)',
        args: [
            {
                type: "field_input",
                name: "CHUNK_NODE_ID",
                text: "CHUNK_NODE_ID",
                check: "String"
            },
            {
                type: "field_input",
                name: "SEMANTIC",
                text: "SEMANTIC",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
    {
        blockName: 'dep',
        message: '(SENTENCE_ID,%1,%2)',
        args: [
            {
                type: "field_input",
                name: "CHUNK_NODE_ID",
                text: "CHUNK_NODE_ID",
                check: "String"
            },
            {
                type: "field_input",
                name: "DEP_NODE_ID",
                text: "DEP_NODE_ID",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
    {
        blockName: 'surf',
        message: '(SENTENCE_ID,%1,%2)',
        args: [
            {
                type: "field_input",
                name: "NODE_ID",
                text: "NODE_ID",
                check: "String"
            },
            {
                type: "field_input",
                name: "NODE_SURFACE",
                text: "NODE_SURFACE",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
    {
        blockName: 'surfBF',
        message: '(SENTENCE_ID,%1,%2)',
        args: [
            {
                type: "field_input",
                name: "MORPH_NODE_ID",
                text: "MORPH_NODE_ID",
                check: "String"
            },
            {
                type: "field_input",
                name: "MORPH_SURFACE",
                text: "MORPH_SURFACE",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
    {
        blockName: 'sloc',
        message: '(SENTENCE_ID,%1,%2)',
        args: [
            {
                type: "field_input",
                name: "NODE_ID",
                text: "NODE_ID",
                check: "String"
            },
            {
                type: "field_input",
                name: "NODE_SLOC",
                text: "NODE_SLOC",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
    {
        blockName: 'pos',
        message: '(SENTENCE_ID,%1,%2)',
        args: [
            {
                type: "field_input",
                name: "MORPH_NODE_ID",
                text: "MORPH_NODE_ID",
                check: "String"
            },
            {
                type: "field_input",
                name: "POS",
                text: "POS",
                check: "String"
            }
        ],
        previousStatement: null,
        nextStatement: null,
        colour: 200
    },
];

export {
    queryBlocks,
    logicalBlocks,
    prologBlocks,
}