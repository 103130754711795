import { useDispatch } from 'react-redux';
import { logout } from 'modules/auth/store';
import PersonIcon from '@mui/icons-material//Person';
import MenuIcon from '@mui/icons-material//Menu';
import LogoutIcon from '@mui/icons-material/Logout';


const LoginUserData = () => {
    const dispatch = useDispatch();
    
    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <>
            <div className="icon">
                <div className="icon_location">
                    <PersonIcon style={{ fontSize: 36 }} />
                    <p className="icon_text">マイページ(未実装)</p>
                </div>
                <div className="icon_location">
                    <MenuIcon style={{ fontSize: 36 }} />
                    <p className="icon_text">メニュー(未実装)</p>
                </div>
                <div className="icon_location" onClick={handleLogout}>
                    <LogoutIcon style={{ fontSize: 36 }} />
                    <p className="icon_text">ログアウト</p>
                </div>
            </div>
        </>
    );

    };


export default LoginUserData;
