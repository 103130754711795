import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    Backdrop,
    Fade,
    Grid,
} from "@mui/material";
import PlayCircleFilled from "@mui/icons-material//PlayCircleFilled";
import "modules/copas/style.css";
import ResultDetail from 'modules/copas/components/ResultDetail';

const ResultButton = () => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [tab, setTab] = useState(0);
    const [page, setPage] = useState(0);
    const { results } = useSelector(state => state.copas);

    const itemsPerPage = 100;
    const totalPages = Math.ceil(results.length / itemsPerPage);

    
 
    if (results.length === 0) return null;

    const handlePageChange = (newPage) => {
        setPage(newPage);
        setIndex(newPage * itemsPerPage); // ページが変わると最初のアイテムを選択
    };

    const paginatedResults = results.slice(page * itemsPerPage, (page + 1) * itemsPerPage);
    
    return (
        <>
            <ListItem button onClick={() => setOpen(true)}>
                {/*<ListItem button onClick={() => Click(true)}>*/}
                <ListItemIcon>
                    <PlayCircleFilled />
                </ListItemIcon>
                <ListItemText primary="解析結果" />
            </ListItem>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                className="modal"
                onClose={() => {
                    setOpen(false);
                    setIndex(0);
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={"modal-box"}>
                        <div className="modal-header">
                            <h2 id="transition-modal-title">
                                テキストの解析結果
                            </h2>
                            <p id="transition-modal-description">
                                文をクリックすることで、アップロードされたテキストの解析結果を確認できます。
                            </p>
                        </div>

                        <Grid container className="plfiles-index">
                            <Grid item xs={2} className="plfiles-index-list">
                                <List>
                                    {paginatedResults.map((result, idx) => (
                                        <ListItem
                                            className="plfiles-index-list-item"
                                            onClick={() => setIndex(idx + page * itemsPerPage)}
                                            key={idx.toString()}
                                            button
                                            disabled={idx + page * itemsPerPage === index}
                                        >
                                            <ListItemText primary={result}></ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                                {/* ページネーション用のボタン */}
                                <div className="pagination-controls">
                                    {Array.from(Array(totalPages).keys()).map(p => (
                                        <button
                                            key={p}
                                            onClick={() => handlePageChange(p)}
                                            disabled={p === page}
                                        >
                                            {p + 1}
                                        </button>
                                    ))}
                                </div>
                            </Grid>
                            <Grid item xs={10}>
                                <ResultDetail
                                    tab={tab}
                                    index={index}
                                    onTabChange={setTab}
                                />

                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default ResultButton;