import React from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';


const HIGHLIGHTTable = ({ data, keyword }) => {
    const [showOnlyHighlighted, setShowOnlyHighlighted] = useState(false);
    //console.log(data)

    return (
        <> 
            <Box component="span" pr={2}>
            キーワード
            </Box>
            <Box component="span" pr={2}>
            {keyword}
            </Box>
            <br/>
            <label>
                <input
                    type="checkbox"
                    checked={showOnlyHighlighted}
                    onChange={() => setShowOnlyHighlighted(!showOnlyHighlighted)}
                />
                強調表示された文のみを表示
            </label>
           
            
            <br />

            {data.map((d, idx) => {
                 if (showOnlyHighlighted && d.every(e => e.center.color === "#000000")) {
                    return null;  
                }
                return (
                    <p key={idx.toString()}>
                        {d.map((e, eidx) => {
                            return (<div key={eidx.toString()}>
                                <span style={{ color: e.left.color }}>{e.left.text}</span>
                                <span style={{ color: e.center.color }}>{e.center.text}</span>
                                <span style={{ color: e.right.color }}>{e.right.text}</span>
                            </div>);
                        })
                        }
                    </p>
                )
            })}
        </>
    );
};

export default HIGHLIGHTTable;