import { djangoClient } from 'api';

const SEARCH_START = "SEARCH_START";
const SEARCH_SUCCESS = "SEARCH_SUCCESS";
const SEARCH_FAILURE = "SEARCH_FAILURE";

const solve = (params) => {
    return async (dispatch) => {
        dispatch({
            type: SEARCH_START
        });
        try {
            //const res = await djangoClient.solve_swipl_v2(params);
            const res = await djangoClient.solve_swipl(params);
            dispatch({
                type: SEARCH_SUCCESS,
                payload: res.data
            });
        } catch {
            dispatch({
                type: SEARCH_FAILURE
            });
        }
    }
};

const initialState = {
    pending: false,
    solutions: []
};



const search = (state = initialState, action) => {
    switch (action.type) {
        case "SEARCH_START":
            return {
                ...state,
                pending: true
            };
        case "SEARCH_SUCCESS":
            return {
                ...state,
                pending: false,
                solutions: action.payload.results
            };
        case "SEARCH_FAILURE":
            return {
                ...state,
                pending: false,
            }
        default:
            return state;
    }
}


export default search;

export {
    solve
}
