import React from "react";
import BlockDefinition from 'modules/workSpace/components/BlockDefinition';
import { queryBlocks, logicalBlocks, prologBlocks } from 'modules/workSpace/config';


const ToolBox = () => {
    return (
        <div className="toolbox">
            <div id="toolbox">
                <BlockDefinition
                    blockConfigs={queryBlocks}
                    categoryName="構文作成"
                    categoryColour="300"
                />
                <BlockDefinition
                    blockConfigs={prologBlocks}
                    categoryName="構文パーツ"
                    categoryColour="200"
                />
                <BlockDefinition
                    blockConfigs={logicalBlocks}
                    categoryName="論理演算子"
                    categoryColour="150"
                />
            </div>
        </div>
    );
};

export default ToolBox;