import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Box, Typography, TextField, Fade } from "@mui/material";
import "modules/auth/style.css";
import { login,reset } from 'modules/auth/store';
import LoginIcon from '@mui/icons-material/Login';


const LoginButton = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    dispatch(reset())
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    setUsername('')
    setPassword('')
  }
  const dispatch = useDispatch()
  

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const message = useSelector(state => state.auth.message);

  
  const LoginSubmit = (event) => {
    event.preventDefault(); // フォームのデフォルト送信を防ぐ
    dispatch(login({
      username: username,
      password: password
    }));
    
  };
  
  
 

  

  return (
    <>
      <div className="icon">
        <div className="icon_location" onClick={handleOpen}>
        <LoginIcon style={{ fontSize: 36 }} />
        <p  className="icon_text">ログイン</p>
        </div>
      </div>      

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="login-modal-title"
        aria-describedby="login-modal-description"
      >
        <Fade in={open}>
          <Box className="login_modal">
            <Typography id="login-modal-title" variant="h6" component="h2">
              <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <h1>ASAGAO</h1>
                <p className="text-xs">日本語意味役割検索システム</p>
              </div>
              <img src={"/asagao.png"} style={{ height: "60px", marginLeft: "10px" }} alt={"日本語意味役割検索システム"} />
            </div>
            </Typography>
            <form onSubmit={LoginSubmit}>
              <TextField
                margin="normal"
                label="ユーザー名"
                variant="outlined"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                label="パスワード"
                type="password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {message && (
              <Typography color="error" variant="body2">
                  {message}
              </Typography>
              )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={LoginSubmit}
            >
              ログイン
            </Button>
            </form>
            <Button
              variant="text"
              color="secondary"
              fullWidth
              sx={{ mt: 1 }}
              onClick={handleClose}
            >
              キャンセル
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default LoginButton;
