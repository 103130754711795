import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import { reset } from 'modules/copas/store';

const ResetButton = () => {
    const { results } = useSelector(state => state.copas) //解析時にロードする場合
    const dispatch = useDispatch();

    if (results.length === 0) return null; //解析時にロードする場合

    return (
        <ListItem button onClick={() => dispatch(reset())}>
            <ListItemIcon>
                <ReplayIcon />
            </ListItemIcon>
            <ListItemText primary="リセット" />
        </ListItem>
    )
}

export default ResetButton;