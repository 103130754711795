import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { ListItem, ListItemIcon, ListItemText, Modal, Backdrop, Fade, Button } from "@mui/material";
import HistoryIcon from '@mui/icons-material/History';
import "modules/copas/style.css";
import PreviousResultDetail from "./PreviousResultDetail";
import { djangoClient } from 'api';

const PreviousResultButton = () => {
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [filedata, setFiledata] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 4; 
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);


    //開いたときに解析ファイル情報を取得
    useEffect(() => {
        if (open) {
            const fetchData = async () => {
                try {
                    const response = await djangoClient.get_file_data();
                    const sortedData = response.data.file_data.sort((a, b) => new Date(b.upload_date) - new Date(a.upload_date));
                    setFiledata(sortedData);　//取得データを格納
                    setTotalPages(Math.ceil(sortedData.length / itemsPerPage));　//取得件数からページ数を計算
                } catch (error) {
                    console.error(error);
                }
            };
        
        fetchData();
        }
    }, [open]);

    if (!isAuthenticated) return null;

    const handleNextPage = () => {
        if (page < totalPages - 1) setPage(page + 1);
    };

    const handlePreviousPage = () => {
        if (page > 0) setPage(page - 1);
    };

    return (
        <>
            <ListItem button onClick={() => setOpen(true)}>
                <ListItemIcon>
                    <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="以前に解析したファイルをロード" />
            </ListItem>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                className="modal"
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={"modal-box"}>
                        <div className="modal-header">
                            <h2 id="transition-modal-title">
                                過去に解析したファイルの一覧を表示
                            </h2>
                            <p id="transition-modal-description">
                                以前解析したファイルをクリックし，ロードボタンをクリックすることでファイルの解析結果をブラウザにロードできます
                            </p>
                        </div>
                        
                        <PreviousResultDetail page={page} itemsPerPage={itemsPerPage} filedata={filedata} />
                        <div>
                            <Button onClick={handlePreviousPage} disabled={page === 0}>
                                前のページ
                            </Button>
                            <Button onClick={handleNextPage} disabled={page === totalPages - 1}>
                                次のページ
                            </Button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default PreviousResultButton;
