import axiosbase from 'axios';
const {
    REACT_APP_DJANGO_API
} = process.env;

/*
@TODO: 暫定での実装
- getBlocklyとpostBlocklyに関しては内部の処理が不明瞭なため適切な命名に変える
- 後々redux-thunk上に乗せたいので利用部の実装を再検討
- メンバはgetとpostのみにして引数でエンドポイントを渡すのが良しだが暫定
*/


class DjangoClient {
    constructor() {
        this.axios = axiosbase.create({
            baseURL: REACT_APP_DJANGO_API,
            withCredentials: true, // CSRFトークンやセッションIDの送信を許可
        });
    }

    async convert(params) {
        return await this.axios.post("/convert", params, {
            'Content-type': 'multipart/form-data'
        });
    }
    async re_convert(params) {
        return await this.axios.post("/re_convert", params, {
            'Content-type': 'application/json'
        });
    }
    async get_convert_results(params) {
        return await this.axios.post("/get/convert-results", params, {
            'Content-type': 'application/json'
        });
    }
    async get_file_data() {
        return await this.axios.get("/get/file-data", {
            'Content-type': 'application/json'
        });
    }

    async solve_swipl(params) {
        return await this.axios.post("/solve/swipl", params, {
            'Content-type': 'application/json'
        });
    }
    async solve_swipl_v2(params) {
        return await this.axios.post("/solve/swipl_v2", params, {
            'Content-type': 'application/json'
        });
    }
    async solve_swipl_svr(params) {
        return await this.axios.post("/solve/swipl_svr", params, {
            'Content-type': 'application/json'
        });
    }
    async login(params){
        return await this.axios.post("/auth/login", params, {
            'Content-type': 'application/json'
        });
    }
    async logout(params){
        return await this.axios.post("/auth/logout", params, {
            'Content-type': 'application/json'
        });
    }
    async getUserInfo(){
        return await this.axios.get("/auth/check_auth", {
            'Content-type': 'application/json'
        });
    }
}

const djangoClient = new DjangoClient();

export {
    djangoClient
};