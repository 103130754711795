import { djangoClient } from 'api';

const CONVERT_START = "CONVERT_START";
const CONVERT_SUCCESS = "CONVERT_SUCCESS";
const CONVERT_FAILURE = "CONVERT_FAILURE";
const RESET = "RESET";


const convertStart = () => {
    return {
        type: CONVERT_START
    }
}

const convert = (params) => {
    return async (dispatch) => {
        dispatch(convertStart())
        try {
            const res = await djangoClient.convert(params);
            console.log(res)
            dispatch({
                type: CONVERT_SUCCESS,
                payload: res.data
            });
        } catch {
            dispatch({
                type: CONVERT_FAILURE
            });
        }
    }
};

const re_convert = (params) => {
    return async (dispatch) => {
        dispatch(convertStart())
        try {
            const res = await djangoClient.re_convert(params);
            dispatch({
                type: CONVERT_SUCCESS,
                payload: res.data
            });
        } catch {
            dispatch({
                type: CONVERT_FAILURE
            });
        }
    }
};
const reset = () => {
    return {
        type: RESET
    }
}

const initialState = {
    pending: false,
    results: [],
    file_id: null //ファイル識別子
};


const copas = (state = initialState, action) => {
    switch (action.type) {
        case "CONVERT_START":
            return {
                ...state,
                pending: true
            };
        case "CONVERT_SUCCESS":
            return {
                ...state,
                pending: false,
                results: action.payload.results, //テキスト部分を保存
                file_id: action.payload.file_id//識別キーを保存
            };
        case "CONVERT_FAILURE":
            return {
                ...state,
                pending: false,
            }
        case "RESET":
            return {
                ...state,
                results: [],
                file_id: null 
            }
        default:
            return state;
    }
}
export default copas

export {
    convert,
    re_convert,
    reset
}