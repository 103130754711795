import React from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper
} from '@mui/material';

const KWICTable = ({ data, keyword }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="right">.....</TableCell>
                        <TableCell align="center" style={{ background: "#fdffe0", width: 100 }} >
                            <p>キーワード</p>
                            <p>{keyword}</p>
                        </TableCell>
                        <TableCell>.....</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.map((d, idx) => (
                            <TableRow key={idx.toString()}>
                                <TableCell align="right">{d.left}</TableCell>
                                <TableCell align="center" style={{ background: "#fdffe0" }}>{d.center}</TableCell>
                                <TableCell align="left">{d.right}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default KWICTable;