import React, { useState } from 'react';
import { List, ListItem, ListItemText, Typography, Button, CircularProgress } from "@mui/material";
import "modules/copas/style.css";
import { useSelector, useDispatch } from 'react-redux';
import { re_convert } from 'modules/copas/store';

const PreviousResultDetail = ({ page, itemsPerPage, filedata }) => {
    const [selectedFileText, setSelectedFileText] = useState(null); 
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const dispatch = useDispatch()
    const { pending } = useSelector(state => state.copas);

    //ファイル選択したとき
    const handleFileClick = (fileId, fileText) => {
        setSelectedFileText(fileText); 
        setIsFileSelected(true); 
        setSelectedFileId(fileId);　
    };
    //ファイルデータをレンダリング
    const renderFiledata = () => {
        const startIndex = page * itemsPerPage;
        const selectedData = filedata.slice(startIndex, startIndex + itemsPerPage);

        return (
            <List>
                {selectedData.map((file) => (
                    <ListItem 
                        button 
                        key={file.file_id} 
                        onClick={() => handleFileClick(file.file_id, file.file_text)}
                        disabled={file.file_id === selectedFileId}  // クリックしたファイル名を無効化
                    >
                        <ListItemText
                            primary={<Typography variant="h6">{file.file_name}</Typography>}
                            secondary={<Typography variant="body2" color="textSecondary">{new Date(file.upload_date).toLocaleString()}</Typography>}
                        />
                    </ListItem>
                ))}
            </List>
        );
    };
    //アップロードボタンを解析データをロード
    const LoadConvertResults = () => {
        dispatch(re_convert(selectedFileId)); //djangoから受け取る
    }



    return (
        <>
            <div style={{ display: 'flex' }}>
                <div className="plfiles-index-list-item" style={{ flex: 1, marginRight: '20px' }}>
                    {filedata.length > 0 ? renderFiledata() : <div>Loading...</div>}
                </div>
                <div className="plfile-detail" style={{ flex: 2 }}>
                    {selectedFileText && (
                        <div>
                            <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                                {selectedFileText}
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
            <div>
                {isFileSelected && (
                    <Button variant="contained" color="primary" style={{ marginTop: '20px' }} onClick = {() => LoadConvertResults()}>
                        クリックしたファイルの解析データをロードする
                    </Button>
                )}
            </div>
            <div className="loading" style={{ display: pending ? 'block' : 'none' }}>
                <div className="loadingInner">
                    <p className="loadgingText">{"コンバート中..."}</p>
                    <CircularProgress style={{ visibility: pending ? 'visible' : 'hidden' }} />
                </div>
            </div>   
        </>
    );
}

export default PreviousResultDetail;
