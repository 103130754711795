import React from 'react';
import { useSelector } from 'react-redux';
import ReplayIcon from '@mui/icons-material/Replay';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

const ClearButton = () => {
    const { workSpace } = useSelector(state => state.workSpaceState);

    const onClick = () => {
        workSpace.clear();
    };

    return (
        <ListItem button onClick={() => onClick()}>
            <ListItemIcon>
                <ReplayIcon />
            </ListItemIcon>
            <ListItemText primary="検索条件をリセット" />
        </ListItem>
    );
};

export default ClearButton;