import {combineReducers} from 'redux';
import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk'
import workSpaceReducer from 'modules/workSpace/store';
import copasReducer from 'modules/copas/store';
import searchReducer from 'modules/search/store';
import authReducer from 'modules/auth/store';

const reducers = combineReducers({
    workSpaceState: workSpaceReducer,
    search: searchReducer,
    copas: copasReducer,
    auth: authReducer
})

const store = createStore(reducers, applyMiddleware(...[thunk]));

export default store;