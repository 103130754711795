import React, { useState } from "react";
import { useSelector } from 'react-redux';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { DropzoneDialog } from 'mui-file-dropzone';
import Blockly from "node-blockly/browser";

const XmlLoadButton = () => {
    const [open, setOpen] = useState(false);
    const { workSpace } = useSelector(state => state.workSpaceState);

    const loadXmlFile = (file) => {
        if (file != null) {
            let reader = new FileReader()
            reader.readAsText(file)
            reader.onload = (e) => {
                let xmltext = reader.result;
                let xmlDom = Blockly.Xml.textToDom(xmltext);
                workSpace.clear()
                Blockly.Xml.domToWorkspace(xmlDom, workSpace)
            }
        } else {
            console.log("file is null")
        }
        setOpen(false);
    };

    return (
        <>
            <ListItem button onClick={() => setOpen(true)}>
                <ListItemIcon>
                    <CloudUploadIcon />
                </ListItemIcon>
                <ListItemText primary="検索条件をファイルからロード" />
            </ListItem>
            <DropzoneDialog
                filesLimit={1}
                acceptedFiles={['.xml']}
                cancelButtonText={"キャンセル"}
                submitButtonText={"読み込む"}
                maxFileSize={5000000}
                open={open}
                onClose={() => setOpen(false)}
                onSave={(files) => loadXmlFile(files[0])}
                showPreviews={true}
                showFileNamesInPreview={true}
                dialogTitle="読み込む検索条件ファイル(XML形式)を選択"
            />
        </>
    );
};

export default XmlLoadButton;