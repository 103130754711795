import React from 'react';
import { useSelector } from "react-redux";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import SaveAlt from "@mui/icons-material//SaveAlt";
import Blockly from "node-blockly/browser";

const XmlSaveButton = () => {
    const { workSpace } = useSelector(state => state.workSpaceState);

    const getDate = () => {
        const date = new Date();
        const Y = date.getFullYear();
        const M = ("00" + (date.getMonth() + 1)).slice(-2);
        const D = ("00" + date.getDate()).slice(-2);
        const h = ("00" + date.getHours()).slice(-2);
        const m = ("00" + date.getMinutes()).slice(-2);
        const s = ("00" + date.getSeconds()).slice(-2);
        return Y + M + D + h + m + s;
    };

    const save = () => {
        let xmlDom = Blockly.Xml.workspaceToDom(workSpace);
        let xmlText = Blockly.Xml.domToText(xmlDom);
        let blob = new Blob([xmlText], { type: "text/xml" });
        let filename = `dom${getDate()}.xml`;
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.target = "_blank";
            a.download = filename;
            a.click();
        }
    };

    return (
        <ListItem button onClick={() => save()}>
            <ListItemIcon>
                <SaveAlt />
            </ListItemIcon>
            <ListItemText primary="検索条件をファイルに保存" />
        </ListItem>
    )
}

export default XmlSaveButton;