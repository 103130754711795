import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import KWICTable from 'modules/search/components/KWICTable';
import HIGHLIGHTTable from 'modules/search/components/HIGHLIGHTTable';
import PreviewModal from 'modules/search/components/PreviewModal';
import { Card, CardContent, Box, InputLabel, MenuItem, FormControl, Select, FormHelperText, Grid, Button } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid"

const SearchResultCard = () => {
    const { solutions } = useSelector(state => state.search);
    const { results } = useSelector(state => state.copas);

    const [format, setFormat] = useState('Table');

    const [key, setKey] = useState('');
    const [index, setIndex] = useState(null);


    const getKeywordOptions = (solutions) => {
        let options = []
        if (solutions[0]) {
            Object.entries(solutions[0]).forEach(([k, v]) => {
                const str_v = String(v)
                const nums = str_v.split("_")

                console.log(nums)
                if (str_v.split("_").length === 2 && typeof (Number(nums[0])) === 'number' && typeof (Number(nums[1])) === 'number') {
                    options.push(k)
                }
            })
        }
        return options
    }


    useEffect(() => {
        // 検索結果が更新された時に、
        let options = getKeywordOptions(solutions)
        if (!options.includes(key)) {
            // キーワードの選択肢の中に、現在選択中のキーがなければ、
            setFormat('Table'); // テーブル表示にして、
            setKey(''); // キーをリセット
        }
    }, [solutions, key])




    const createKwicData = () => {
        try {
            return solutions.map(sol => {
                const { SENTENCE_ID } = sol;
                //const rowSentence = results[SENTENCE_ID]['asa_json']['surface'];
                const rowSentence = results[SENTENCE_ID];

                //let loc_sted = sol[key].substring(1, sol[key].length - 1) //prologpy &swipl_svr
                //const [start, end] = loc_sted.split("_"); //prologpy&swipl_svrs

                const [start, end] = sol[key].split("_"); //pyswip

                //console.log(sol[key])
                //console.log(end)
                return {
                    left: rowSentence.slice(0, start),
                    center: rowSentence.slice(start, Number(end) + 1),
                    right: rowSentence.slice(Number(end) + 1)
                }
            });
        } catch {
            return [];
        }
    };

    const createData = () => {
        try {
            let columns = [
                { field: 'id', hide: false },
                {
                    field: 'editBtn',
                    headerName: ' ',
                    sortable: false,
                    width: 100,
                    disableClickEventBubbling: true,
                    renderCell: (params) => <Button variant="contained" size="small" color="primary" onClick={() => setIndex(params.row["SENTENCE_ID"])}>詳細</Button>,
                },
            ];
            const columns_next = Object.keys(solutions[0]).map(k => { return { field: k, headerName: k, width: 250 }; });
            columns = columns.concat(columns_next);
            const rows = solutions.map((sol, idx) => { return { ...sol, id: idx, SENTENCE_ID: Number(sol["SENTENCE_ID"]) }; });
            return {
                rows: rows,
                columns: columns
            };
        } catch {
            return {
                rows: [],
                columns: []
            };
        }
    };


    // 与えられたkeyに対して色付けした構造を返す
    // left, center, right という構造で，centerが key
    const createHighlightData = () => {
        try {
            let data = [];
            results.forEach((res, idx) => {
                // res = {
                //     'asa_json':json,
                //     'predicates':plorog述語,
                //     '.string'
                // }
                //let surface = res.asa_json.surface
                let surface = res
                let innerData = [];

                solutions.forEach(sol => {
                    //if (sol["SENTENCE_ID"] === idx.toString()) {//swipl_svr
                    if (sol["SENTENCE_ID"] === idx) {
                        //console.log(sol[key])
                        //let loc_sted = sol[key].substring(1, sol[key].length - 1)// prologpy&swipl_svr
                        let [start, end] = sol[key].split("_");
                        //let [start, end] = loc_sted.split("_");//prologpy&swipl_svr
                        console.log(surface) // koichi
                        console.log(start) // koichi

                        let push_data = {
                            left: {
                                text: surface.slice(0, start),
                                color: '#000000',
                            },
                            center: {
                                text: surface.slice(start, Number(end) + 1),
                                color: '#ff0000',
                            },
                            right: {
                                text: surface.slice(Number(end) + 1),
                                color: "#000000"
                            }
                        }
                        //console.log(push_data.left.text)
                        //console.log(push_data)
                        innerData.push(push_data)
                    }
                });
                
                if (innerData.length === 0) {
                    innerData.push({
                        left: {
                            text: '',
                            color: '#000000',
                        },
                        center: {
                            text: surface,
                            color: '#000000',
                        },
                        right: {
                            text: '',
                            color: "#000000"
                        }
                    })
                }
                
                data.push(innerData);
            });
            return data;
        } catch {
            return [];
        }
    };

    return (
        <>
            <Card style={{ margin: 10, height: "100%" }}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={3}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="format-label">
                                        表示形式
                                    </InputLabel>
                                    <Select
                                        labelId="format-label"
                                        id="format"
                                        value={format}
                                        label="Format"
                                        onChange={(e) => setFormat(e.target.value)}
                                    >
                                        <MenuItem value='KWIC'>KWIC</MenuItem>
                                        <MenuItem value='HIGHLIGHT'>強調</MenuItem>
                                        <MenuItem value='Table'>テーブル</MenuItem>
                                    </Select>
                                    <FormHelperText>検索結果の表示形式を指定します。</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={1} />
                        {['KWIC', 'HIGHLIGHT'].includes(format) &&
                            <Grid item xs={3}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="keyword-label">
                                            キーワード
                                        </InputLabel>
                                        <Select
                                            labelId="keyword-label"
                                            id="keyword"
                                            value={key}
                                            label="Keyword"
                                            onChange={(e) => setKey(e.target.value)}
                                        >
                                            {getKeywordOptions(solutions).map(k => {
                                                return <MenuItem value={k} key={k.toString()}>{k}</MenuItem>
                                            })}
                                        </Select>
                                        <FormHelperText>
                                            {
                                                format === 'KWIC'
                                                    ? "キーワードにする要素を選択します。sloc形式(数字_数字)の要素のみ選択できます。"
                                                    : format === 'HIGHLIGHT'
                                                        ? "強調する要素を選択します。\nsloc形式(数字_数字)の要素のみ選択できます。"
                                                        : ""
                                            }
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                    {format === 'KWIC' && <KWICTable data={createKwicData()} keyword={key} />}
                    {format === 'HIGHLIGHT' && <HIGHLIGHTTable data={createHighlightData()} keyword={key} />}
                    {format === 'Table' && <div style={{ height: '60vh', width: "100%" }}><DataGrid {...createData()} /></div>}
                </CardContent>
            </Card>
            <PreviewModal index={index} setIndex={setIndex} />
        </>
    );
};

export default SearchResultCard;
