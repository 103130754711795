import React from "react";
import ToolBox from "modules/workSpace/components/ToolBox";
import ControlPanel from "../components/ControlPanel";
import WorkSpace from "modules/workSpace/components/WorkSpace";
import { Grid } from '@mui/material'
import '../css/Main.css'
import PrologQuery from "modules/copas/components/PrologQuery";
const Main = () => {
  return (
    <Grid container className="main">
      <Grid item xs={3} className="leftBar">
        <ControlPanel />
      </Grid>
      <Grid item xs={9} className="main-content">
        <ToolBox />
        <WorkSpace />
        <PrologQuery />
      </Grid>
    </Grid>
  );
}

export default Main;
