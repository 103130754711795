import React from "react";
import { Provider} from "react-redux";
import Header from "./layouts/Header";
import Main from "./layouts/Main";
import SearchResultCard from 'modules/search/components/SearchResultCard';
import store from './store';
import "modules/search/style.css";


const App = () => {


    return (<Provider store={store}>
        <div className="wrapper">
            <Header />
            <Main />
            <SearchResultCard />
        </div>
    </Provider>
    );
}

export default App;