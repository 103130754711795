import React from 'react';
import { useSelector } from 'react-redux';
import Blockly from "node-blockly/browser";

const PrologQuery = () => {
    const { workSpace } = useSelector(state => state.workSpaceState)
    if(workSpace){
        let code = Blockly.JavaScript.workspaceToCode(workSpace)
        if(code !== ""){
            return(
                <p>{code}</p>
            )
        }else{
            return(
                <p>構文を作成してください</p>
            )
        }
        
    }else{
        return(
            <p>構文を作成してください</p>
        )
    }
}

export default PrologQuery;